import React from 'react';
//Page components
import AboutSection from '../components/AboutSection';
import ServicesSection from '../components/ServicesSection';
import FaqSection from '../components/FaqSection';
//Animations import
import { motion } from 'framer-motion';
import { pageAnimation } from '../animation';
import ScrollToTop from '../components/ScrollToTop';

const AboutUs = () => {
    return (
        
        <motion.div exit="exit" variants={pageAnimation} initial="hidden" animate="show">
        <ScrollToTop/>
        <AboutSection />
        <ServicesSection />
        <FaqSection />
        </motion.div>

    ); 
    
};    

export default AboutUs;